export const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
        return text;
    }
    return text.slice(0, maxLength) + '...';
}
export const buildFileContent = (activeItem) => {
    const sortedData = [
        { key: 'Order ID', value: activeItem.order_id },
        { key: 'Name', value: activeItem.data.name },
        { key: 'Phone', value: activeItem.data.phone },
        { key: 'Real Phone', value: activeItem.realPhone },
        { key: 'IP', value: activeItem.data.ip },
        { key: 'Date', value: activeItem.date },
    ];

    return sortedData.map(item => `${item.key}: ${item.value}`).join('\n');
};
export const downloadTextFile = (filename, text) => {
    const element = document.createElement('a');
    const file = new Blob([text], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
};
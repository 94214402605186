import React, { useEffect, useState } from "react";
import {
    DataTable,
    TableRow,
    TableCol,
    Alert, Pagination,
    BottomSheet,
    Button,
    ButtonIcon,
    Badge,
    SimpleToast,
    ShowToast,
} from "react-facebook-ui";
import { apiURL, mainUrl } from "..";
import { findPhoneNumbersInText } from 'libphonenumber-js'
import { buildFileContent, downloadTextFile, truncateText } from '../utils/index.jsx';
import { IconAdblock, IconCopy, IconDelete, IconDownload, IconWhatsapp } from "../traits/icons.js";
import Clipboard from 'clipboard';
import slug from "slug";
import { useInstance } from "../providers/Instance.jsx";
import Sheet from "./Sheet.jsx";

const SpamTable = ({ activeType }) => {
    const [currentPage, setcurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [error, setError] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [activeItem, setActiveItem] = useState(false);
    const [isOpen, openBottomSheet] = useState(false);

    const { instance, changeInstance } = useInstance();
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(false);
            setData([]);
            try {
                const request = await fetch(apiURL + `/?pedidos=${instance}&page=${currentPage}&spam=1`,{
                    credentials: "same-origin"
                  }).then(res => res.json());
                if (request.error) {
                    setError(request.msg);
                    setTotalPages(0);
                } else {
                    setTotalPages(request.pages);
                    for (let k in request.data) {
                        const e = request.data[k];
                        request.data[k].nationalNumber = false;
                        if (e.data.phone) {
                            try {
                                const phoneNumber = findPhoneNumbersInText(e.data.phone, 'MX');
                                if (phoneNumber.length === 1) {
                                    request.data[k].realPhone = e.data.phone;
                                    request.data[k].data.phone = phoneNumber[0].number.number;
                                    request.data[k].nationalNumber = phoneNumber[0].number.nationalNumber;
                                }
                            } catch (error) {
                                console.log(error);
                            }
                        }
                    }
                    setData(request.data);
                }
            } catch (error) {
                console.log(error);
                setError(true);
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [instance, activeType, currentPage]);
    useEffect(() => {
        const clipboard = new Clipboard(`[data-clipboard-text]`);
        clipboard.on('success', function (e) {
            ShowToast(<SimpleToast color={'success'}>copiado</SimpleToast>, { sticky: false });
        });
        return () => {
            clipboard.destroy();
        };
    }, [isOpen]);
    if (error) {
        return <Alert color={'danger'}>
            <h2>Error Fatal</h2>
            <p> Ocurrio un error al intentar procesar los datos, revise consola para mas detalles. </p>
        </Alert>;
    }
    return <>
        <div style={{ margin: '-8px -8px -8px' }}>
        <DataTable data={data}
                multiselect={false}
                isLoading={loading}
                Row={(item) =>{
                    if(activeItem===item){
                        item = activeItem;
                    }
                    return(
                        <TableRow key={item.order_id} onClick={() => {
                            setActiveItem(item);
                            openBottomSheet(true);
                        }} color={item?.deleted ? 'black' : (item?.repeated_name ? 'danger' : (item?.repeated_phone ? 'warning' : (item?.data?.blocked ? 'pink' : '')))}>
                            <TableCol name={"Nombre"} width={20}>
                                {(item?.name || '') === '' ? console.log(item) : false}
                                {truncateText(item?.name || '', 28)}
                            </TableCol>
                            <TableCol sort={'Telefono'} name={"Telefono"} width={20}>
                                {item?.data?.phone}
                            </TableCol>
                        </TableRow>
                    )
                }} />
                    <BottomSheet showState={[isOpen, openBottomSheet]}>
                <Sheet activeItem={activeItem} setActiveItem={setActiveItem} openBottomSheet={openBottomSheet} />
            </BottomSheet>
            <Pagination pageState={[currentPage, setcurrentPage]} totalPages={totalPages} color={'primary'} />
        </div>
    </>
}
export default SpamTable;
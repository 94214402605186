import React, { createContext, useContext, useEffect, useState } from 'react';

const SideContext = createContext();

export const SideProvider = ({ children }) => {
    const [side, setSide] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const changeSide = (newSide) => {
        setSide(newSide);
    };
    useEffect(()=>{
        if(window.innerWidth >= 1000){
            setSide(true);
            setWidth(window.innerWidth);
        }
        const handleResize = () =>{
            setWidth(window.innerWidth);
            if(window.innerWidth >= 1000){
                setSide(true);
            }
        }
        window.addEventListener("resize", handleResize);
        return()=>window.removeEventListener("resize", handleResize);
    },[]);
    return (
        <SideContext.Provider value={{ side, changeSide, width}}>
            {children}
        </SideContext.Provider>
    );
}

export const useSide = () => useContext(SideContext);

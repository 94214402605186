import { Fragment, useState } from "react";
import { Button, ButtonIcon, LoadingBar } from "react-facebook-ui";
import { IconAdblock, IconCopy, IconDelete, IconDownload, IconWhatsapp } from "../traits/icons";
import { truncateText } from "../utils";
import slug from "slug";
import { apiURL } from "..";
import { useInstance } from "../providers/Instance";

const Sheet = ({ activeItem, setActiveItem, openBottomSheet }) => {
    const [loading, setLoading] = useState(false);
    const { instance, changeInstance } = useInstance();
    const deleteItem = async () => {
        if (loading) {
            return;
        }
        setLoading(true);
        try {
            const req = await fetch(apiURL + `/?instance=${instance}&delete=${btoa(activeItem.path)}`, {
                credentials: "same-origin"
            }).then(res => res.json());
            activeItem.deleted = true;
            setActiveItem(activeItem);
            openBottomSheet(false);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }
    const blockItem = async () => {
        if (loading) {
            return;
        }
        setLoading(true);
        try {
            const req = await fetch(apiURL + `/?instance=${instance}&block=${btoa(activeItem.path)}`, {
                credentials: "same-origin"
            }).then(res => res.json());
            if (activeItem?.data?.blocked) {
                activeItem.data.blocked = false;
            } else {
                activeItem.data.blocked = true;
            }
            setActiveItem(activeItem);
            openBottomSheet(false);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }
    const generateWhatsappLink = (text) => {
        const encodedText = encodeURIComponent(text);
        return `https://wa.me/?text=${encodedText}`;
    };
    return (
        <Fragment>
            {loading && (<LoadingBar color={'primary'} height={3} skipMargin={true} />)}
            {activeItem && (
                <div className="xne3--">
                    {activeItem?.order_id && (
                        <div className="fnx">
                            <span>Id: {activeItem.order_id}</span>
                            <ButtonIcon data-clipboard-text={activeItem.order_id} icon={<IconCopy />} active="teal" />
                        </div>
                    )}
                    {activeItem?.data?.name && (
                        <div className="fnx">
                            <span>Nombre: {activeItem.data.name}</span>
                            <ButtonIcon data-clipboard-text={activeItem.data.name} icon={<IconCopy />} active="teal" />
                        </div>
                    )}
                    {activeItem?.data?.phone && (
                        <div className="fnx">
                            <span>Tel: {activeItem.data.phone}</span>
                            <ButtonIcon data-clipboard-text={activeItem.data.phone} icon={<IconCopy />} active="teal" />
                        </div>
                    )}
                    {activeItem?.realPhone && (
                        <div className="fnx">
                            <span>TIP: {activeItem?.realPhone || 'noTIP'}</span>
                            <ButtonIcon data-clipboard-text={activeItem?.realPhone || 'noTIP'} icon={<IconCopy />} active="teal" />
                        </div>
                    )}
                    {activeItem?.data?.ip && (
                        <div className="fnx">
                            <span>Ip: {activeItem.data?.ip || 'noip'}</span>
                            <ButtonIcon data-clipboard-text={activeItem.data?.ip || 'noip'} icon={<IconCopy />} active="teal" />
                        </div>
                    )}
                    {activeItem?.date && (
                        <div className="fnx">
                            <span>Fecha: {activeItem.date}</span>
                            <ButtonIcon data-clipboard-text={activeItem.date} icon={<IconCopy />} active="teal" />
                        </div>
                    )}
                    {(activeItem?.name && activeItem?.data?.phone) && (
                        <div className="fnx">
                            <span>{truncateText(slug(activeItem.name + '--' + activeItem.data.phone), 35)}</span>
                            <ButtonIcon onClick={() => {
                                downloadTextFile(slug(activeItem.name + '--' + activeItem.data.phone) + '.txt', buildFileContent(activeItem))
                            }} icon={<IconDownload />} active="teal" />
                        </div>
                    )}
                    {(activeItem?.name && activeItem?.realPhone) && (
                        <div className="fnx">
                            <span>{truncateText(slug(activeItem.name + '--' + activeItem?.nationalNumber), 35)}</span>
                            <a
                                href={generateWhatsappLink(`${activeItem.name} ${activeItem?.nationalNumber}`)}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="button-icon"
                                style={{ color: 'teal' }}
                            >
                                <IconWhatsapp />
                            </a>
                        </div>
                    )}
                    {activeItem?.path && (
                        <div className="fnx xx--">
                            <Button onClick={deleteItem} color="danger" rounded><IconDelete className="btbx" />Eliminar</Button>
                            {activeItem?.data?.ip && (
                                <Button onClick={blockItem} color="deep-orange" rounded>Bloquear<IconAdblock className="btbx x3ss" /></Button>
                            )}
                        </div>
                    )}
                </div>
            )}
        </Fragment>
    )
}
export default Sheet;
import { useEffect, useState } from "react";
import { ButtonIcon, NavSmall, Navbar } from "react-facebook-ui";
import logo from "../assets/logo.svg";
import { IconClose, IconList } from "../traits/icons";
import { useSide } from "../providers/Side";

const Header = ({show = false}) => {
    const [smallNavOpen, set_smallNavOpen] = useState(false);
    const { side, changeSide } = useSide();
    return <>
        <Navbar fixed={false} >
            <NavSmall
                toggleState={[smallNavOpen, set_smallNavOpen]}
                toggle={<ButtonIcon icon={ !side ? <IconList /> : <IconClose />}
                    color={smallNavOpen ? undefined : "transparent"}
                    active={smallNavOpen ? 'primary' : undefined}
                    onClick={() => changeSide(o => !o)}
                />}
                logo={<img src={logo} alt="logo" />}>
            </NavSmall>
        </Navbar>
    </>;
};

export default Header;
import React, { useState } from "react";
import { Input, Button, useForm, LoadingBar, ShowToast, SimpleToast } from "react-facebook-ui";
import logoUrl from '../assets/logo.svg';
import { apiURL, mainUrl } from "..";
import { useAuth } from "../providers/Auth";

const Login = () => {
    const {auth, changeAuth} = useAuth();
    const [loading, setLoading] = useState(false);
    const form = useForm();
    const handleClick = async () => {
        if(loading){
            return;
        }
        setLoading(true);
        const username = form.value.username;
        const password = form.value.password;
        const response = await fetch(apiURL + '?auth=true', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            credentials: "same-origin",
            body: new URLSearchParams({
                username: username,
                password: password,
            }),
        }).then(res=>res.json());
        if(response.isLoggedIn){
            changeAuth(true);
            ShowToast(<SimpleToast color={'success'}>Bienvenido</SimpleToast>,{sticky:false});
        }else{
            changeAuth(false);
            ShowToast(<SimpleToast color={'danger'}>Usuario o Contraseña incorrecta</SimpleToast>,{sticky:false});
        }
        setLoading(false);
    };
    return <div className="loginf">
        <img className="logo" src={logoUrl} alt="logo" />
        <div className="card">
            <Input
                form={form}
                formName='username'
                type="text"
                placeholder={'Nombre de usuario'}
                label={'usuario'}
                labelWidth='100px'
                required
            />

            <Input
                form={form}
                formName='password'
                type="password"
                placeholder={'password'}
                label={'Contraseña'}
                labelWidth='100px'
                required
            />
            <Button onClick={handleClick} color="primary" rounded>Iniciar Sesion</Button>

            {loading&&(
                <LoadingBar color={'primary'} height={3} skipMargin={false}/>
            )}
        </div>
    </div>
}

export default Login;
import ReactDOM from 'react-dom/client'
import AppWrapper from './AppWrapper.js'
import App from './App.js';
import { AuthProvider } from './providers/Auth.jsx';
import "./index.css";
import { SideProvider } from './providers/Side.jsx';
import { InstanceProvider } from './providers/Instance.jsx';
export const mainUrl = 'https://mexico.halura.com.mx/Ventas';
export const apiURL = 'https://mexico.halura.com.mx';


if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/service-worker.js`)
            .then(registration => {
                console.log('ServiceWorker registration successful with scope: ', registration.scope);
            })
            .catch(error => {
                console.log('ServiceWorker registration failed: ', error);
            });
    });
}


ReactDOM.createRoot(document.getElementById("root")).render(
    <AuthProvider>
        <SideProvider>
            <InstanceProvider>
                <AppWrapper>
                    <App />
                </AppWrapper>
            </InstanceProvider>
        </SideProvider>
    </AuthProvider>
)
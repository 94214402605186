import React, { createContext, useContext, useEffect, useState } from 'react';
const InstanceContext = createContext();

export const InstanceProvider = ({ children }) => {
    const [instance, setInstance] = useState(null);
    const changeInstance = (newInstance) => {
        setInstance(newInstance);
    };
    return (
        <InstanceContext.Provider value={{ instance, changeInstance}}>
            {children}
        </InstanceContext.Provider>
    );
}

export const useInstance = () => useContext(InstanceContext);

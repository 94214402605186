import React, { useState } from "react";
import { HorizontalTabs, Tab } from "react-facebook-ui";
import PostsTable from "./PostsTable";
import Search from "./Search";
import BlockedsTable from "./BlockedsTable";
import SpamTable from "./SpamTable";

const TabsTable = () => {
    const tabI = () =>{
        const wp = window.location.hash?.substr(1)?.toLocaleLowerCase();
        if (wp === 'pedidos') {
            return 1;
        }
        if (wp === 'blocks') {
            return 2;
        }
        if (wp === 'search') {
            return 3;
        }
        if (wp === 'spam') {
            return 4;
        }
        return 1;
    }
    const [selectedTab, setSelectedTab] = useState(tabI());
    if(selectedTab===false){
        return<></>;
    }
    return <div className={"card"}>
        <HorizontalTabs tabState={[selectedTab, (e)=>{
            setSelectedTab( parseInt(e) )
        }]} color={'primary'}>
            <Tab tab_id={1} href={'#pedidos'}>Pedidos</Tab>
            <Tab tab_id={3} href={'#search'}>Buscar</Tab>
            <Tab tab_id={2} href={'#blocks'}>Bloqueos</Tab>
            <Tab tab_id={4} href={'#spam'}>Spam</Tab>
        </HorizontalTabs>
        <div className="mt-1 pt-1">
            {parseInt(selectedTab) === 1 && <PostsTable activeType={selectedTab} />}
            {parseInt(selectedTab) === 2 && <BlockedsTable activeType={selectedTab} />}
            {parseInt(selectedTab) === 4 && <SpamTable activeType={selectedTab} />}
            {parseInt(selectedTab) === 3 && <Search />}
        </div>
    </div>
}
export default TabsTable;
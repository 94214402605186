import React, { useState, useEffect } from 'react';
import { Button } from 'react-facebook-ui';
import { apiURL } from '..';
import { IconBell, IconBellSlash } from '../traits/icons';

const PushNotificationToggle = () => {
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [registration, setRegistration] = useState(null);
    const [publicKey, setPublicKey] = useState('');

    useEffect(() => {
        fetch(apiURL + '?vpaid=1',{
            credentials: "same-origin"
          })
            .then(response => response.json())
            .then(data => {
                setPublicKey(data.publicKey);
            });

        if ('serviceWorker' in navigator && 'PushManager' in window) {
            navigator.serviceWorker.ready.then(reg => {
                setRegistration(reg);
                reg.pushManager.getSubscription().then(subscription => {
                    setIsSubscribed(subscription !== null);
                });
            });
        }
    }, []);

    const subscribeUser = async () => {
        if (registration && publicKey) {
            const applicationServerKey = urlBase64ToUint8Array(publicKey);
            const subscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: applicationServerKey
            });
            sendSubscriptionToServer(subscription);
            setIsSubscribed(true);
        }
    };

    const unsubscribeUser = async () => {
        if (registration) {
            const subscription = await registration.pushManager.getSubscription();
            if (subscription) {
                await subscription.unsubscribe();
                removeSubscriptionFromServer(subscription);
                setIsSubscribed(false);
            }
        }
    };

    const handleToggle = () => {
        if (isSubscribed) {
            unsubscribeUser();
        } else {
            subscribeUser();
        }
    };

    const sendSubscriptionToServer = (subscription) => {
        const subscriptionData = {
            endpoint: subscription.endpoint,
            keys: {
                auth: btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('auth')))),
                p256dh: btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('p256dh'))))
            }
        };

        fetch(apiURL + '?vpaidadd=1', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "same-origin",
            body: JSON.stringify(subscriptionData)
        });
    };

    const removeSubscriptionFromServer = (subscription) => {
        const subscriptionData = {
            endpoint: subscription.endpoint,
            keys: {
                auth: btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('auth')))),
                p256dh: btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('p256dh'))))
            }
        };

        fetch(apiURL + '?vpaiddel=1', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "same-origin",
            body: JSON.stringify(subscriptionData)
        });
    };

    function urlBase64ToUint8Array(base64String) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }

    return (
        <Button color={isSubscribed ? 'success' : 'primary'} onClick={handleToggle}>
            {isSubscribed ? 'Disable Push Notifications' : 'Enable Push Notifications'}
            {isSubscribed ? <IconBellSlash className={'xn33mm'} /> : <IconBell className={'xn33mm'} />}
        </Button>
    );
};

export default PushNotificationToggle;
import { Fragment, useEffect, useState } from 'react'
import { SidebarContainer, Sidebar, Card, ButtonIcon, Button, Grid, AutoHideScroll, Alert, Badge } from "react-facebook-ui";
import TabsTable from './components/TabsTable';
import { useAuth } from './providers/Auth';
import Login from './components/Login';
import Header from './components/Header';
import { useSide } from './providers/Side';
import { useInstance } from './providers/Instance';
import { apiURL } from '.';
import { IconCheck } from './traits/icons';
import PushNotificationToggle from './components/PushNotificationToggle';
const SelectInstance = ({ webInstances = [] }) => {
    const { instance, changeInstance } = useInstance();
    const { side, changeSide, width } = useSide();

    return (
        <div style={{ padding: '15px' }}>
            <Grid
                small={1}
                medium={3}
                custom={[
                    { columns: 3, min: 400, max: 720 },
                    { columns: 1, min: 0, max: 400 }
                ]}
                gap="10px"
                data={webInstances}
                template={(item) =>
                    <Card
                        key={item}
                        fit={false}
                        color={"primary"}
                        stickHeaderToBody={false}
                        headerBorder={false}
                        header={<div>
                            <h3 className="d-flex flex-fill">{item}</h3>
                            <ButtonIcon color={"light"} icon={<IconCheck />} onClick={() => {
                                changeSide(false);
                                changeInstance(item);
                            }} />
                        </div>}
                        imageTop={<iframe
                            scrolling={'no'}
                            style={{
                                width: '100%',
                                height: 'auto',
                                aspectRatio: '9 / 16',
                                overflow: 'hidden',
                                border: 'none',
                                pointerEvents: 'none',
                                cursor: 'alias',
                                userSelect: 'none'
                            }}
                            src={`${apiURL}/${item}/`} />}
                        footer={<div className="d-flex flex-fill justify-content-end">
                            <Button color="light" onClick={() => {
                                changeSide(false);
                                changeInstance(item);
                            }}>Seleccionar</Button>
                        </div>}>
                    </Card>}
            />
        </div>
    )
}
const App = () => {
    const { auth } = useAuth();
    const { side, changeSide, width } = useSide();
    const [webInstances, setWebInstances] = useState(false);
    const { instance, changeInstance } = useInstance();
    useEffect(() => {
        const fetchData = async () => {
            const req = await fetch(apiURL + '?instances=1',{
                credentials: "same-origin"
              }).then(res => res.json());
            setWebInstances(req.data);
        }
        if (!instance && auth) {
            fetchData();
        }
    }, [instance, auth]);
    if (!auth) {
        return <Login />
    }
    return (
        <Fragment>
            <SidebarContainer
                SidebarLeft={
                    <Sidebar showState={[side, changeSide]}
                        pushable={true}
                        bottomSheetProps={false}
                        width={width > 400 ? 400 : width}
                        overNavbar={true}
                        responsive={false}
                        mobileWidth={width > 400 ? 400 : width}
                        className="bg-card border-1 border-color-default">
                        <Header show={true} />
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', padding: '15px' }}>
                            <PushNotificationToggle />
                        </div>
                        {(instance && side) && (
                            <AutoHideScroll height={window.outerHeight - 80}>
                                <SelectInstance webInstances={webInstances || []} />
                            </AutoHideScroll>
                        )}
                    </Sidebar>}
                SidebarRight={
                    <Sidebar showState={[false, (v) => { }]}
                        width={80}
                        pushable={true}>
                    </Sidebar>}
            >
                <Header />
                <div style={{ padding: '15px' }}>
                    {instance && (
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            background: '#fff',
                            borderRadius: '10px',
                            maxWidth: '200px',
                            margin: '0 auto',
                            border: '1px solid #dddfe2',
                            borderTop: '0',
                            marginTop: '10px',
                            fontSize: '15px',
                            fontWeight: '600',
                            color: '#656774',
                            gap: '8px',
                            padding: '5px'
                        }} onClick={() => {
                            changeSide(true);
                        }}>
                            web:
                            <Badge pill color="teal" variant={4}>{instance}</Badge>
                        </div>
                    )}
                </div>
                {instance ? <TabsTable /> : (
                    <SelectInstance webInstances={webInstances || []} />
                )}
            </SidebarContainer>
        </Fragment>
    )
}
export default App;
import React, { createContext, useContext, useEffect, useState } from 'react';
import { apiURL, mainUrl } from '..';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(false);
    const changeAuth = (newAuth) => {
        setAuth(newAuth);
    };
    useEffect(()=>{
        const check = async () =>{
            const request = await fetch(apiURL + '/?auth=check',{
                credentials: "same-origin"
              }).then(res=>res.json());
            if(request && request.isLoggedIn){
                setAuth(true);
            }
        }
        check();
    },[]);
    return (
        <AuthContext.Provider value={{ auth, changeAuth}}>
            {children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => useContext(AuthContext);
